<template>
  <div class="login">
    <div class="left">
      <!-- <img :src="leftImg" width="707" /> -->
      <div class="top">
        <div class="item" style="border-radius: 2px  100px  2px  100px;">
          <div class="title">
            <img src="@/assets/loginLeft1.png" alt="">
            <span>覆盖全行业品牌数据库</span>
          </div>
          <div class="listItem"><img src="@/assets/loginLeftIcon.png" alt="">整合工业供应链领域品牌官方数据</div>
          <div class="listItem"><img src="@/assets/loginLeftIcon.png" alt="">包含欧美系、日系及国产主流品牌型号</div>
          <div class="listItem"><img src="@/assets/loginLeftIcon.png" alt="">直连品牌官网同步更新产品参数与选代信息</div>
          <span class="icon" style="right: 13px; bottom: 13px;"></span>
        </div>
        <div class="item" style="border-radius: 100px  2px  100px  2px;">
          <div class="title">
            <img src="@/assets/loginLeft2.png" alt="">
            <span>精准获客引擎</span>
          </div>
          <div class="listItem"><img src="@/assets/loginLeftIcon.png" alt=""> 根据设置代理与经营品牌自动获取客户询价</div>
          <div class="listItem"><img src="@/assets/loginLeftIcon.png" alt=""> 根据设置接单产品自动智能报价</div>
          <div class="listItem"><img src="@/assets/loginLeftIcon.png" alt=""> 接收精准询价需求(行业区配度>99%)</div>
          <span class="icon" style="left: 13px; bottom: 13px;"></span>
        </div>
        <div class="item" style="border-radius: 100px  2px  100px  2px;">
          <div class="title">
            <img src="@/assets/loginLeft3.png" alt="">
            <span>全流程采购解决方案</span>
          </div>
          <div class="listItem"><img src="@/assets/loginLeftIcon.png" alt=""> 接入优质供应商资源序(含原厂直供采道)</div>
          <div class="listItem"><img src="@/assets/loginLeftIcon.png" alt=""> 参数化精准选型，AI智能推荐替代型号。</div>
          <div class="listItem"><img src="@/assets/loginLeftIcon.png" alt=""> 批量加入采购清单比价</div>
          <div class="listItem"><img src="@/assets/loginLeftIcon.png" alt=""> 一键发起询价/采购流程</div>
          <span class="icon" style="right: 13px; top: 13px;"></span>
        </div>
        <div class="item" style="border-radius: 2px  100px  2px  100px;">
          <div class="title">
            <img src="@/assets/loginLeft4.png" alt="">
            <span>工业互联网智能工作台</span>
          </div>
          <div class="listItem"><img src="@/assets/loginLeftIcon.png" alt="">智能报价管理、生成专居询价链接自动收集报价,多供应商报价智能对比</div>
          <div class="listItem"><img src="@/assets/loginLeftIcon.png" alt="">合同与物流系统:自动生成电子合同(预置合规条款)物流信息实时追踪看板</div>
          <span class="icon" style="left: 13px; top: 13px;"></span>
        </div>
      </div>
      <div class="bottom">
        <div class="title">
          <span>下载盘雷桌面端</span>
          <div class="download">
            <a class="btn" href="https://www.panlei.cn/desktop/files/panlei.exe">立即下载</a>
            <img src="@/assets/rightArrow.png" alt="">
          </div>
        </div>
        <div class="list">
          <span>1.下载盘雷桌面端，免费使用盘雷参数选型</span>
          <span>2.行业最全品牌数据库，快速查找型号</span>
          <span>3.加入推广者计划,使用AI智能选型</span>
          <span>4.升级推广者，获取市场价格及供应商信息</span>
        </div>

      </div>
    </div>
    <div class="right" :style="{ height: pageType === 'register' ? '850px' : '670px' }">
      <!--  二维码提示扫码   -->
      <img v-if="!isShowQr" :src="qrLogin" alt="" class="qrLogin" width="100" height="100" @click="isShowQr = true" />
      <img v-else :src="phoneImg" alt="" class="qrLogin" width="100" height="100" @click="isShowQr = false" />

      <!--  表单 登录  -->
      <div v-if="!isShowQr">
        <div class="title">
          <img src="../../../assets/logo.png" alt="">
          <span>欢迎来到盘雷</span>
        </div>
        <div style="position: relative; z-index: 10">
          <login-form v-show="pageType === 'login'" @getPlatformContact="getPlatformContact" @changePage="changePage"
            @register="register" :isSharePage="false" />
          <div style="padding: 0 100px" v-show="pageType === 'register'">
            <noAccountNumberForm @changePage="changePage" />
          </div>
          <forget-password @changePage="changePage" v-show="pageType === 'forgetPassword'" />
          <code-login @changePage="changePage" v-show="pageType === 'codeLogin'" />
        </div>
      </div>

      <!--  二维码扫码区域  -->
      <div v-else class="qrContainer">
        <wxlogin self_redirect="false" appid="wx75ee94f1e9d5bc07" scope="snsapi_login" :redirect_uri="loginSuccessUrl">
        </wxlogin>
      </div>
    </div>
    <el-dialog v-model="dialogVisible" title="" width="421px" :before-close="handleClose">
      <div class="modal-content">
        <img :src="groupImg" alt="二维码" style="width: 200px; ">
        <h2>扫码加入用户交流群</h2>
        <ul style="text-align: left; margin: 15px 0;">
          <h2>加入群组,即享以下特权</h2>
          <span class="line"></span>
          <li>抢先了解产品更新内容</li>
          <li>专人解答产品使用问题</li>
          <li>获取盘雷工业供应链最热资讯</li>
        </ul>
      </div>
    </el-dialog>
    <el-dialog v-model="registerDialogVisible" title="" width="642px" :before-close="registerDialogVisibleClose">
      <div class="reg-content">
        <span class="title">如何注册?</span>
        <div class="num">
          <div class="num_item">
            <div class="method">
              <img src="../../../assets/num_1.png" alt="">
              <span>受邀注册(推荐)</span>
            </div>
            <span>你的合作伙伴已注册盘雷？请对方发送盘雷邀请链接网址或手机链接完成注册。</span>
          </div>
          <div class="num_item">
            <div class="method">
              <img src="../../../assets/num_2.png" alt="">
              <span>自主注册</span>
              <span style="font-size: 12px;">（请慎重选择！盘雷注册类型一经注册不可修改，错误选择将导致核心功能受限）</span>
            </div>
          </div>
        </div>
        <div class="type">
          <span class="title">盘雷注册类型</span>
          <span class="line"></span>
          <span>供应商:代理商/贸易商/门店（同时进行销售与采购）</span>
          <span style="color: #28A39E; cursor: pointer;" @click="goGYS">注册盘雷供应商用户 -></span>
          <span>客户：设备厂/集成商/终端用户（仅采购需求）</span>
          <span style="color: #28A39E; cursor: pointer;" @click="gouser">注册盘雷客户用户 -></span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import loginForm from "../components/loginForm.vue"; //登录
import noAccountNumberForm from "../components/noAccountNumberForm.vue"; //注册
import forgetPassword from "../components/forgetPassword.vue"; //忘记密码
import codeLogin from "../components/codeLogin.vue"; //验证码登录
import loginMixin from "../mixins/loginMixin.js";
import { mapActions, mapMutations } from "vuex";
import { ElMessage } from "element-plus";
import wxlogin from "vue-wxlogin";
import Vue from "vue";
export default {
  data() {
    return {
      loginBg: require("@/assets/loginBg.png"),
      logo: require("@/assets/logo.jpg"),
      pageType: "login",
      qrLogin: require("../../../assets/qrLogin.png"),
      phoneImg: require("../../../assets/shouji.png"),
      isShowQr: false,
      loginSuccessUrl: encodeURIComponent("https://www.panlei.cn/login/index"),
      dialogVisible: true,
      registerDialogVisible: false,
      groupImg: ''
    };
  },
  mixins: [loginMixin],
  components: {
    loginForm,
    noAccountNumberForm,
    forgetPassword,
    wxlogin,
    codeLogin,
  },
  methods: {
    register() {
      this.registerDialogVisible = true
    },
    registerDialogVisibleClose() {
      this.registerDialogVisible = false
    },
    goGYS() {
      window.open('https://www.panlei.cn/login/index?uid=1115261540900012032&belongType=0', '_blank');
    },
    gouser() {
      window.open('https://www.panlei.cn/login/index?uid=1115261540900012032&belongType=1', '_blank');
    },
    handleClose() {
      this.dialogVisible = false
    },
    ...mapActions("loginModule", [
      "uWechatLogin",
      "uWechatGetSessionKey",
      //扫码登录
    ]),
    ...mapMutations(["renewToken", "renewUserInfo"]),
    changePage(type) {
      this.pageType = type;
    },
    getPlatformContact(e) {
      var id = 36
      console.log(e.data)
      e.data.forEach(item => {
        if (item.id == id) this.groupImg = item.img
      })
    },
    jumpPage() {
      this.$router.push({
        path: "/home/index",
      });
    },

    wlogin() {
      this.uWechatLogin(this.$route.query.code).then((res) => {
        let { code, data, msg } = res.data;
        if (code === "0" && data.token) {
          //登录成功
          this.renewToken(res.data.data.token); //更新token
          this.renewUserInfo(res.data.data);
          //保存操作信息（登录成功后）
          localStorage.setItem(
            "loginSign",
            JSON.stringify({
              isLogin: "0",
              isRememberPas: "0",
            })
          );

          this.dialogFormVisible = false;
          this.jumpPage(); //跳转到登录首页
        } else {
          ElMessage({
            showClose: true,
            message: msg || data.msg,
            type: "error",
          });
        }
      });
    },
  },
  created() {
    if (this.$route.query.uid) {
      this.pageType = "register";
    }
    if (this.$route.query.code) {
      this.wlogin();

      // this.uWechatGetSessionKey('011Jhm0w3vjFq03oMd2w3zhtNI1Jhm0Y').then((res) => {
      //   if(res.data?.data&&res.data.data.openid){

      //   }
      // });
    }
    console.log("this.$route.query:", this.$route.query);
  },
  beforeMount() {
    console.log("this.$route.query:", this.$route.query);
  },
  mounted() {
    console.log("this.$route.query:", this.$route.query);
  },
  setup(props, context) { },
  expose: ["increment"],
  emits: ["check"],
  // watch: {
  //   '$route.path': function (val) {
  //     console.log(val)
  //     // this.wxLoginRedirect()
  //   }
  // }
  watch: {
    $route: {
      handler(val, oldval) {
        console.log(val); //新路由信息
        console.log(oldval); //老路由信息
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style scoped lang="less">
.login {
  background: url('../../../assets/loginBg.png') no-repeat center;
}

.left {
  height: 85%;
  padding-left: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .top {
    display: flex;
    flex-wrap: wrap;
    width: 746px;
    height: 362px;
    justify-content: space-between;
    align-content: space-between;

    .item {
      width: 365px;
      height: 173px;
      padding: 30px 40px;
      box-sizing: border-box;
      background: #E4FFFC;
      position: relative;

      .icon {
        position: absolute;
        width: 10px;
        height: 10px;
        background: #28A39E;
        border-radius: 50%;
      }

      .title {
        display: flex;
        align-items: flex-start;
        margin-bottom: 16px;

        img {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }

        span {
          font-size: 18px;
          font-weight: 600;
          display: inline-block;
        }
      }

      .listItem {
        display: flex;
        align-items: center;
        margin-right: 12px;
        font-size: 14px;
        color: #2E2E2E;

        img {
          width: 6px;
          height: 6px;
          margin-right: 16px;
        }
      }
    }
  }

  .bottom {
    width: 649px;
    height: 174px;
    background-color: #f0f0f0;
    border-radius: 2px;
    display: flex;
    flex-direction: column;

    .title {
      width: 100%;
      height: 60px;
      background: url('../../../assets/downloadBg.png') no-repeat center;
      font-size: 14px;
      display: flex;
      align-items: center;
      padding: 0 24px;
      box-sizing: border-box;

      span {
        font-weight: 600;
        margin-right: 35px;
      }

      .download {
        display: flex;
        align-items: center;

        a {
          color: #28A39E;
        }

        img {
          width: 14px;
          height: 9px;
          margin-left: 4px;
        }
      }
    }

    .list {
      width: 100%;
      padding: 16px 24px;
      font-size: 13px;
      display: flex;
      color: #6A6D6D;
      flex-direction: column;
    }
  }
}

.right {
  min-width: 620px;
  height: 670px;
  background: #fff;
  box-shadow: 0 0 20px rgb(0 0 0 / 16%);
  position: absolute;
  right: 40px;

  .logo {
    margin: 30px 0 26px 30px;
    width: 100px;
  }

  .title {
    width: 370px;
    margin: 0 auto;
    font-size: 30px;
    font-weight: 600;
    padding-bottom: 40px;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 61px;
      height: 68px;
    }

    span {
      font-size: 32px;
      color: #2E2E2E;
      margin-top: 12px;
    }
  }

  .bottomImg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  .qrLogin {
    position: absolute;
    right: 0;
    top: 0;
  }

  .qrContainer {
    transform: translate(25%, 25%);
  }

  .box-item {
    position: absolute;
    left: 20px;
    bottom: 20px;
    width: 90px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    color: #fff;
    background-color: #47af17;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .el-input__inner,
  .el-textarea__inner,
  .el-button--default {
    border: none !important;
  }

  .el-button--text,
  .el-button--text:focus,
  .el-button--text:hover,
  .el-button--text:active,
  .el-button--default:focus,
  .el-button--default:hover,
  .el-button--default:active {
    color: #000 !important;
  }

  :deep(.el-popconfirm__main) {
    margin-bottom: 5px !important;
    display: inline-block;
  }
}

.left-img {
  position: relative;
  /* 使文本可以绝对定位 */
}

.left-text {
  position: absolute;
  top: 20%;
  /* 根据需要调整位置 */
  left: 10%;
  /* 根据需要调整位置 */
  color: #fff;
  /* 根据需要调整文字颜色 */
  text-align: left;
  /* 根据需要调整对齐方式 */
}

.modal-content {
  background: url('../../../assets/callBg.png') no-repeat center;
  padding: 60px 30px 30px;
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 14px;
    color: #117D79;
    margin-top: 10px;
  }

  ul {
    padding: 20px;
    color: #070707;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      text-align: center;
      margin-bottom: 10px;
      font-size: 18px;
      color: #2E2E2E;
    }

    .line {
      width: 100%;
      height: 2px;
      border-image: linear-gradient(116deg, rgba(40, 163, 158, 0), rgba(40, 163, 158, 1), rgba(40, 163, 158, 0)) 1 1;
    }

    li {
      display: flex;
      align-items: center;

      span {
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        margin-right: 10px;
        color: #2E2E2E;
      }
    }
  }
}

.reg-content {
  background: url('../../../assets/regBg.png') no-repeat center;
  // background-size: cover;
  padding: 87px 56px;

  .title {
    color: #2E2E2E;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 28px;
    display: inline-block;
  }

  .num {
    .num_item {
      .method {
        display: flex;
        align-items: center;
        white-space: nowrap;
        margin-bottom: 20px;
        font-size: 16px;


        img {
          width: 25px;
          height: 25px;
          margin-right: 6px;
        }
      }

      >span {
        white-space: nowrap;
        margin-bottom: 20px;
        display: inline-block;
        font-size: 14px;
      }
    }
  }

  .type {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    span {
      display: inline-block;
      margin-bottom: 20px;
    }

    .title {
      color: #28A39E;
      font-size: 14px;
      margin-bottom: 2px;
    }

    .line {
      background: linear-gradient(270deg, rgba(81, 208, 205, 0) 0%, #51D0CD 58%, #28A39E 100%);
      width: 100%;
      height: 2px;
    }
  }
}
</style>
